export type * as _SolidState from "./_model";
import { create, createGlobal } from "./create";
import { createAsync, createAsyncGlobal } from "./create-async";

export const solidstate = {
  create,
  createGlobal,
  createAsync,
  createAsyncGlobal,
};
